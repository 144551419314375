import Resources from '../../helpers/resources';
import { getValueFromUrl } from '../../helpers/utils';

export const isMarkActivityDisabled = ({ isLoading, userResponse }) => (
  isLoading || !userResponse || !userResponse.isAttempted
);

export const getTextSpeechConfig = () => ({
  authToken: Resources.env.authToken,
  serviceHostUrl: window._env_.TextToSpeechHostUrl,
  dataUrl: window._env_.TextToSpeechDataUrl,
  locale: getValueFromUrl('locale') || 'en-AU',
  localisationCdnBaseUrl: window._env_.TextToSpeechLocalisationCdnBaseUrl,
  // eslint-disable-next-line max-len
  speechless: ['sq', 'hy', 'az', 'be', 'bs', 'ceb', 'co', 'hr', 'eo', 'et', 'fy', 'ka', 'ht', 'ha', 'haw', 'hmn', 'ig', 'ga', 'jv', 'kk', 'km', 'rw', 'ku', 'ky', 'lo', 'la', 'lb', 'mk', 'mg', 'mt', 'mi', 'mn', 'my', 'ne', 'ny', 'or', 'ps', 'fa', 'sm', 'gd', 'st', 'sn', 'sd', 'si', 'sl', 'so', 'su', 'sw', 'tg', 'tt', 'tk', 'ug', 'uz', 'xh', 'yi', 'yo', 'zu']
});

export const getDifferenceInSeconds = ({ startDate, endDate }) =>
  Math.round((endDate.getTime() - startDate.getTime()) / 1000);

export default isMarkActivityDisabled;
